<template>
  <div class="more">
    <div class="moreUl">
      <div class="moreLi" @click="goOldSystem('/pages/home/community/index')">
        <div class="left">
          <img :src="`${publickPath}static/img/icons/sqsq.png`" alt="" />
          <p>社区社群</p>
        </div>
        <div class="right"><i class="iconfont iconyou-"></i></div>
      </div>
      <div class="moreLi" @click="goSys()">
        <div class="left">
          <img :src="`${publickPath}static/img/icons/jhsb.png`" alt="" />
          <p>假货识别</p>
        </div>
        <div class="right"><i class="iconfont iconyou-"></i></div>
      </div>

      <div class="moreLi baoliao" @click="toXcx()">
        <div class="left">
          <img :src="`${publickPath}static/img/icons/wybl.png`" alt="" />
          <p>我要爆料</p>
        </div>
        <div class="right"><i class="iconfont iconyou-"></i></div>

        <div class="baoliaoWx" @click="toXcx12()">
          <wx-open-launch-weapp
            style="baoliao"
            id="gh_1cb844965933"
            username="gh_1cb844965933"
            path="/pages/authorize/authorize.html"
          >
            <script type="text/wxtag-template">
              <style>
              		.text{
              			height: 100px;
              			width: 375px;
              			display: block;
              			color: #888;
                    opacity: 0;
              		}
              	</style>
              	<div class="text">tiaozhuanxiaochengxu</div>
            </script>
          </wx-open-launch-weapp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var wx = require("weixin-js-sdk");
export default {
  data() {
    return {
      publickPath: process.env.BASE_URL,
    };
  },
  watch: {},
  methods: {
    goOldSystem(path) {
      if (path) {
        this.$globalFun.setUrlHeader(path);
      } else {
      }
    },
    toXcx() {
      alert("123");
      // wx.miniProgram.navigateTo({url: '/pages/authorize/authorize.html'})
    },
    toXcx12() {
      alert("888");
      // wx.miniProgram.navigateTo({url: '/pages/authorize/authorize.html'})
    },

    //扫一扫
    goSys() {
      this.$wx.scanQRCode({
        // needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        // scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        // success: function (res) {
        //   var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        // },
        needResult: 1,
        success: (res) => {
          console.log("scanCode", res);
          if (res.resultStr.indexOf("http") > -1) {
            window.location.href = res.resultStr;
          } else {
            alert(res.resultStr.split(",")[1]);
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scope>
.more {
  width: 100%;
  .moreUl {
    width: 100%;
    overflow-x: hidden;
    .moreLi {
      position: relative;
      margin-bottom: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem 2rem;
      box-shadow: 0px 10px 10px 0px rgba(184, 184, 184, 0.2);
      border-radius: 8px;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 2.4rem;
          height: 2.4rem;
        }
        p {
          margin-left: 0.8rem;
          font-size: 0.8rem;
        }
      }
      .right {
        color: #999;
      }
    }

    .baoliao {
      position: relative;
    }
    .baoliaoWx {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
    }
  }
  .moreList {
    .list {
      padding-top: 1.5rem;
      width: 25%;
      float: left;
      img {
        width: 2rem;
        height: 2rem;
      }
      p {
        margin-top: 0.3rem;
        font-size: 0.6rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
